import { LoginInWebViewPage } from "@pages/login-in-web-view";
import { createFileRoute } from "@tanstack/react-router";

interface LoginInWebViewRouteParameters {
  firebasetoken: string;
}

export const Route = createFileRoute("/login-in-web-view/")({
  component: LoginInWebViewPage,
  validateSearch: (
    search: Record<string, string>,
  ): LoginInWebViewRouteParameters => ({
    firebasetoken: search.firebasetoken,
  }),
});
