import { FC, useEffect } from "react";
import { useLoginMutation } from "@generated/graphql.tsx";
import { useAuthStore } from "@shared/auth-store/lib/auth.store.ts";

import { Route } from "../../../routes/login-in-web-view";

export const LoginInWebViewPage: FC = () => {
  const { firebasetoken } = Route.useSearch();
  const navigate = Route.useNavigate();
  const authStore = useAuthStore();
  const [, loginMutation] = useLoginMutation();

  useEffect(() => {
    const anonymousLogin = async (): Promise<void> => {
      if (!firebasetoken) {
        console.warn("No firebase token found");
        return;
      }

      console.info("Attempting anonymous login with token:", firebasetoken);
      try {
        const response = await loginMutation({ firebaseToken: firebasetoken });

        if (!response.data?.loginViaFirebaseToken) {
          throw new Error(
            response.error
              ? `Login mutation error: ${response.error}`
              : "Login mutation failed",
          );
        }

        authStore.setAccessToken(response.data.loginViaFirebaseToken);
        globalThis.location.href = "https://getoutf.it";
        console.info("Login successful, response:", response);
      } catch (error) {
        console.error("Error during login:", error);
      }
    };

    anonymousLogin();
  }, [firebasetoken, loginMutation, authStore, navigate]);

  return <></>;
};
