/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../routes/__root'
import { Route as LoginInWebViewIndexImport } from './../routes/login-in-web-view/index'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()
const RegisterIndexLazyImport = createFileRoute('/register/')()
const LoginIndexLazyImport = createFileRoute('/login/')()
const AboutIndexLazyImport = createFileRoute('/about/')()
const ProfileProfileIdLazyImport = createFileRoute('/profile/$profileId')()
const PicksCreateLazyImport = createFileRoute('/picks/create')()
const PicksPickIdLazyImport = createFileRoute('/picks/$pickId')()
const ItemItemIdLazyImport = createFileRoute('/item/$itemId')()
const ProfileEditProfileIdLazyImport = createFileRoute(
  '/profile/edit/$profileId',
)()

// Create/Update Routes

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../routes/index.lazy').then((d) => d.Route))

const RegisterIndexLazyRoute = RegisterIndexLazyImport.update({
  id: '/register/',
  path: '/register/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/register/index.lazy').then((d) => d.Route),
)

const LoginIndexLazyRoute = LoginIndexLazyImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/login/index.lazy').then((d) => d.Route),
)

const AboutIndexLazyRoute = AboutIndexLazyImport.update({
  id: '/about/',
  path: '/about/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/about/index.lazy').then((d) => d.Route),
)

const LoginInWebViewIndexRoute = LoginInWebViewIndexImport.update({
  id: '/login-in-web-view/',
  path: '/login-in-web-view/',
  getParentRoute: () => rootRoute,
} as any)

const ProfileProfileIdLazyRoute = ProfileProfileIdLazyImport.update({
  id: '/profile/$profileId',
  path: '/profile/$profileId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/profile/$profileId.lazy').then((d) => d.Route),
)

const PicksCreateLazyRoute = PicksCreateLazyImport.update({
  id: '/picks/create',
  path: '/picks/create',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/picks/create.lazy').then((d) => d.Route),
)

const PicksPickIdLazyRoute = PicksPickIdLazyImport.update({
  id: '/picks/$pickId',
  path: '/picks/$pickId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/picks/$pickId.lazy').then((d) => d.Route),
)

const ItemItemIdLazyRoute = ItemItemIdLazyImport.update({
  id: '/item/$itemId',
  path: '/item/$itemId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/item/$itemId.lazy').then((d) => d.Route),
)

const ProfileEditProfileIdLazyRoute = ProfileEditProfileIdLazyImport.update({
  id: '/profile/edit/$profileId',
  path: '/profile/edit/$profileId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/profile/edit.$profileId.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/item/$itemId': {
      id: '/item/$itemId'
      path: '/item/$itemId'
      fullPath: '/item/$itemId'
      preLoaderRoute: typeof ItemItemIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/picks/$pickId': {
      id: '/picks/$pickId'
      path: '/picks/$pickId'
      fullPath: '/picks/$pickId'
      preLoaderRoute: typeof PicksPickIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/picks/create': {
      id: '/picks/create'
      path: '/picks/create'
      fullPath: '/picks/create'
      preLoaderRoute: typeof PicksCreateLazyImport
      parentRoute: typeof rootRoute
    }
    '/profile/$profileId': {
      id: '/profile/$profileId'
      path: '/profile/$profileId'
      fullPath: '/profile/$profileId'
      preLoaderRoute: typeof ProfileProfileIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/login-in-web-view/': {
      id: '/login-in-web-view/'
      path: '/login-in-web-view'
      fullPath: '/login-in-web-view'
      preLoaderRoute: typeof LoginInWebViewIndexImport
      parentRoute: typeof rootRoute
    }
    '/about/': {
      id: '/about/'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/login/': {
      id: '/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/register/': {
      id: '/register/'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof RegisterIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/profile/edit/$profileId': {
      id: '/profile/edit/$profileId'
      path: '/profile/edit/$profileId'
      fullPath: '/profile/edit/$profileId'
      preLoaderRoute: typeof ProfileEditProfileIdLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/item/$itemId': typeof ItemItemIdLazyRoute
  '/picks/$pickId': typeof PicksPickIdLazyRoute
  '/picks/create': typeof PicksCreateLazyRoute
  '/profile/$profileId': typeof ProfileProfileIdLazyRoute
  '/login-in-web-view': typeof LoginInWebViewIndexRoute
  '/about': typeof AboutIndexLazyRoute
  '/login': typeof LoginIndexLazyRoute
  '/register': typeof RegisterIndexLazyRoute
  '/profile/edit/$profileId': typeof ProfileEditProfileIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/item/$itemId': typeof ItemItemIdLazyRoute
  '/picks/$pickId': typeof PicksPickIdLazyRoute
  '/picks/create': typeof PicksCreateLazyRoute
  '/profile/$profileId': typeof ProfileProfileIdLazyRoute
  '/login-in-web-view': typeof LoginInWebViewIndexRoute
  '/about': typeof AboutIndexLazyRoute
  '/login': typeof LoginIndexLazyRoute
  '/register': typeof RegisterIndexLazyRoute
  '/profile/edit/$profileId': typeof ProfileEditProfileIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/item/$itemId': typeof ItemItemIdLazyRoute
  '/picks/$pickId': typeof PicksPickIdLazyRoute
  '/picks/create': typeof PicksCreateLazyRoute
  '/profile/$profileId': typeof ProfileProfileIdLazyRoute
  '/login-in-web-view/': typeof LoginInWebViewIndexRoute
  '/about/': typeof AboutIndexLazyRoute
  '/login/': typeof LoginIndexLazyRoute
  '/register/': typeof RegisterIndexLazyRoute
  '/profile/edit/$profileId': typeof ProfileEditProfileIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/item/$itemId'
    | '/picks/$pickId'
    | '/picks/create'
    | '/profile/$profileId'
    | '/login-in-web-view'
    | '/about'
    | '/login'
    | '/register'
    | '/profile/edit/$profileId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/item/$itemId'
    | '/picks/$pickId'
    | '/picks/create'
    | '/profile/$profileId'
    | '/login-in-web-view'
    | '/about'
    | '/login'
    | '/register'
    | '/profile/edit/$profileId'
  id:
    | '__root__'
    | '/'
    | '/item/$itemId'
    | '/picks/$pickId'
    | '/picks/create'
    | '/profile/$profileId'
    | '/login-in-web-view/'
    | '/about/'
    | '/login/'
    | '/register/'
    | '/profile/edit/$profileId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  ItemItemIdLazyRoute: typeof ItemItemIdLazyRoute
  PicksPickIdLazyRoute: typeof PicksPickIdLazyRoute
  PicksCreateLazyRoute: typeof PicksCreateLazyRoute
  ProfileProfileIdLazyRoute: typeof ProfileProfileIdLazyRoute
  LoginInWebViewIndexRoute: typeof LoginInWebViewIndexRoute
  AboutIndexLazyRoute: typeof AboutIndexLazyRoute
  LoginIndexLazyRoute: typeof LoginIndexLazyRoute
  RegisterIndexLazyRoute: typeof RegisterIndexLazyRoute
  ProfileEditProfileIdLazyRoute: typeof ProfileEditProfileIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  ItemItemIdLazyRoute: ItemItemIdLazyRoute,
  PicksPickIdLazyRoute: PicksPickIdLazyRoute,
  PicksCreateLazyRoute: PicksCreateLazyRoute,
  ProfileProfileIdLazyRoute: ProfileProfileIdLazyRoute,
  LoginInWebViewIndexRoute: LoginInWebViewIndexRoute,
  AboutIndexLazyRoute: AboutIndexLazyRoute,
  LoginIndexLazyRoute: LoginIndexLazyRoute,
  RegisterIndexLazyRoute: RegisterIndexLazyRoute,
  ProfileEditProfileIdLazyRoute: ProfileEditProfileIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/item/$itemId",
        "/picks/$pickId",
        "/picks/create",
        "/profile/$profileId",
        "/login-in-web-view/",
        "/about/",
        "/login/",
        "/register/",
        "/profile/edit/$profileId"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/item/$itemId": {
      "filePath": "item/$itemId.lazy.tsx"
    },
    "/picks/$pickId": {
      "filePath": "picks/$pickId.lazy.tsx"
    },
    "/picks/create": {
      "filePath": "picks/create.lazy.tsx"
    },
    "/profile/$profileId": {
      "filePath": "profile/$profileId.lazy.tsx"
    },
    "/login-in-web-view/": {
      "filePath": "login-in-web-view/index.tsx"
    },
    "/about/": {
      "filePath": "about/index.lazy.tsx"
    },
    "/login/": {
      "filePath": "login/index.lazy.tsx"
    },
    "/register/": {
      "filePath": "register/index.lazy.tsx"
    },
    "/profile/edit/$profileId": {
      "filePath": "profile/edit.$profileId.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
