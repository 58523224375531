import { FC, useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-store");
    const parsedToken = token ? JSON.parse(token).state.accessToken : "";

    if (!parsedToken && location.pathname !== "/login-in-web-view") {
      navigate({ to: "/login" });
    }
  }, [navigate]);

  return children;
};
