/* eslint-disable */
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type ItemObject = {
  __typename?: 'ItemObject';
  description: Scalars['String']['output'];
  feedId: Scalars['String']['output'];
  feedName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  oldPrice?: Maybe<Scalars['String']['output']>;
  picture: Scalars['String']['output'];
  price: Scalars['String']['output'];
  url: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export type ItemOrPictureObject = ItemObject | PictureObject;

export type Mutation = {
  __typename?: 'Mutation';
  clearCache: Scalars['Boolean']['output'];
  echo: Scalars['String']['output'];
  echoMutation: Scalars['String']['output'];
  loginViaFirebaseToken: Scalars['String']['output'];
};


export type MutationEchoArgs = {
  text: Scalars['String']['input'];
};


export type MutationEchoMutationArgs = {
  text: Scalars['String']['input'];
};


export type MutationLoginViaFirebaseTokenArgs = {
  firebaseToken: Scalars['String']['input'];
};

export type PickObject = {
  __typename?: 'PickObject';
  id: Scalars['String']['output'];
  items: Array<ItemOrPictureObject>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PictureObject = {
  __typename?: 'PictureObject';
  id: Scalars['String']['output'];
  pictureUrl: Scalars['String']['output'];
};

export type ProfileObject = {
  __typename?: 'ProfileObject';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picks: Array<PickObject>;
  profile: Scalars['String']['output'];
  textForSharing: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  cacheSize: Scalars['Float']['output'];
  debug: Scalars['JSON']['output'];
  echo: Scalars['String']['output'];
  item: ItemOrPictureObject;
  pick: PickObject;
  profile: ProfileObject;
};


export type QueryEchoArgs = {
  text: Scalars['String']['input'];
};


export type QueryItemArgs = {
  feedId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};


export type QueryPickArgs = {
  id: Scalars['String']['input'];
};


export type QueryProfileArgs = {
  id: Scalars['String']['input'];
};

export type LoginMutationVariables = Exact<{
  firebaseToken: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', loginViaFirebaseToken: string };

export type EchoQueryVariables = Exact<{
  text: Scalars['String']['input'];
}>;


export type EchoQuery = { __typename?: 'Query', echo: string };

export type ItemQueryVariables = Exact<{
  itemId: Scalars['String']['input'];
  feedId: Scalars['String']['input'];
}>;


export type ItemQuery = { __typename?: 'Query', item: { __typename: 'ItemObject', id: string, name: string, vendor: string, description: string, price: string, oldPrice?: string | null, picture: string, url: string, feedName: string, feedId: string } | { __typename: 'PictureObject', id: string, pictureUrl: string } };

export type PickQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PickQuery = { __typename?: 'Query', pick: { __typename?: 'PickObject', id: string, title: string, subtitle: string, items: Array<{ __typename: 'ItemObject', id: string, name: string, vendor: string, description: string, price: string, oldPrice?: string | null, picture: string, url: string, feedName: string, feedId: string } | { __typename: 'PictureObject', id: string, pictureUrl: string }> } };

export type ProfileQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'ProfileObject', id: string, name: string, profile: string, description: string, avatarUrl?: string | null, textForSharing: string, picks: Array<{ __typename?: 'PickObject', id: string, title: string, subtitle: string, items: Array<{ __typename: 'ItemObject', id: string, name: string, vendor: string, description: string, price: string, oldPrice?: string | null, picture: string, url: string, feedName: string, feedId: string } | { __typename: 'PictureObject', id: string, pictureUrl: string }> }> } };


export const LoginDocument = gql`
    mutation Login($firebaseToken: String!) {
  loginViaFirebaseToken(firebaseToken: $firebaseToken)
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const EchoDocument = gql`
    query Echo($text: String!) {
  echo(text: $text)
}
    `;

export function useEchoQuery(options: Omit<Urql.UseQueryArgs<EchoQueryVariables>, 'query'>) {
  return Urql.useQuery<EchoQuery, EchoQueryVariables>({ query: EchoDocument, ...options });
};
export const ItemDocument = gql`
    query Item($itemId: String!, $feedId: String!) {
  item(itemId: $itemId, feedId: $feedId) {
    ... on PictureObject {
      __typename
      id
      pictureUrl
    }
    ... on ItemObject {
      __typename
      id
      name
      vendor
      description
      price
      oldPrice
      picture
      url
      feedName
      feedId
    }
  }
}
    `;

export function useItemQuery(options: Omit<Urql.UseQueryArgs<ItemQueryVariables>, 'query'>) {
  return Urql.useQuery<ItemQuery, ItemQueryVariables>({ query: ItemDocument, ...options });
};
export const PickDocument = gql`
    query Pick($id: String!) {
  pick(id: $id) {
    id
    title
    subtitle
    items {
      ... on PictureObject {
        __typename
        id
        pictureUrl
      }
      ... on ItemObject {
        __typename
        id
        name
        vendor
        description
        price
        oldPrice
        picture
        url
        feedName
        feedId
      }
    }
  }
}
    `;

export function usePickQuery(options: Omit<Urql.UseQueryArgs<PickQueryVariables>, 'query'>) {
  return Urql.useQuery<PickQuery, PickQueryVariables>({ query: PickDocument, ...options });
};
export const ProfileDocument = gql`
    query Profile($id: String!) {
  profile(id: $id) {
    id
    name
    profile
    description
    avatarUrl
    textForSharing
    picks {
      id
      title
      subtitle
      items {
        ... on PictureObject {
          __typename
          id
          pictureUrl
        }
        ... on ItemObject {
          __typename
          id
          name
          vendor
          description
          price
          oldPrice
          picture
          url
          feedName
          feedId
        }
      }
    }
  }
}
    `;

export function useProfileQuery(options: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<ProfileQuery, ProfileQueryVariables>({ query: ProfileDocument, ...options });
};